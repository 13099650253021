import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import * as moment from "moment";
import { SavedFilters } from "src/app/models/general-filters";
import { CurrentPage, Period } from "src/app/models/filters";
import { SELECTED_FILTERS } from "src/app/tools/constants/filters";
import { Section, ViewLevel } from "src/app/tools/types/global";

@Injectable({
  providedIn: "root",
})
export class FiltersStateService {
  /**** GENERAL FILTERS
   * Filters used in dashboard component
   * ****/

  // selected countries
  private countriesSource = new Subject<any[]>();
  countries$ = this.countriesSource.asObservable();
  countries: any[];
  private _countriesInitial: any[];
  countriesQParams;

  // selected retailers
  private retailersSource = new Subject<any[]>();
  retailers$ = this.retailersSource.asObservable();
  retailers: any[];
  private _retailersInitial: any[];
  retailersQParams;

  // selected period
  private periodSource = new Subject<Period>();
  period$ = this.periodSource.asObservable();
  period: Period;
  private _periodInitial: Period;
  periodQParams;

  // selected sectors
  private sectorsSource = new Subject<any[]>();
  sectors$ = this.sectorsSource.asObservable();
  sectors: any[];
  private _sectorsInitial: any[];
  sectorsQParams;

  // selected categories
  private categoriesSource = new Subject<any[]>();
  categories$ = this.categoriesSource.asObservable();
  private hideCategoriesSource = new Subject<boolean>(); // only used in other-tools component
  hideCategories$ = this.hideCategoriesSource.asObservable();
  categories: any[];
  private _categoriesInitial: any[];
  categoriesQParams;

  // selected campaigns
  private cammpaignsSource = new Subject<any[]>();
  campaigns$ = this.cammpaignsSource.asObservable();
  campaigns: any[];
  private _campaignsInitial: any[];
  campaignsQParams;

  // selected sources
  private sourcesSource = new Subject<any[]>();
  sources$ = this.sourcesSource.asObservable();
  sources: any[];
  private _sourcesInitial: any[];
  sourcesQParams;

  // selected sentiment
  private sentimentSource = new Subject<any[]>();
  sentiments$ = this.sourcesSource.asObservable();
  sentiments: any[];
  private _sentimentsInitial: any[];
  sentimentsQParams;

  // filtersChange
  private filtersSource = new Subject<boolean>();
  filtersChange$ = this.filtersSource.asObservable();

  /**** RETAIL FILTERS
   * Filters used in retailer component
   * ****/

  // source
  private retailSourcesSource = new Subject<any[]>();
  retailSources$ = this.retailSourcesSource.asObservable();
  retailSources: any[];
  retailSourcesQParams;

  // medium
  private retailMediumsSource = new Subject<any[]>();
  retailMediums$ = this.retailMediumsSource.asObservable();
  retailMediums: any[];
  retailMediumsQParams;

  // audiences
  private retailAudiencesSource = new Subject<any[]>();
  retailAudiences$ = this.retailAudiencesSource.asObservable();
  retailAudiences: any[];
  retailAudiencesQParams;

  // filtersChange
  private retailFiltersSource = new Subject<boolean>();
  retailFiltersChange$ = this.retailFiltersSource.asObservable();

  // filters storage
  private currentPageSource = new Subject<CurrentPage>();
  currentPage$ = this.currentPageSource.asObservable();
  currentPage: CurrentPage;

  selectedFilters: SavedFilters = SELECTED_FILTERS;
  inStorageFilters: boolean;
  savedFiltersRef: any[];

  get countriesInitial(): any[] {
    return this._countriesInitial;
  }

  set countriesInitial(value: any[]) {
    this._countriesInitial = value;
    if (!this.inStorageFilters) {
      this.saveFilters(true, "countries");
    }
  }

  get retailersInitial(): any[] {
    return this._retailersInitial;
  }

  set retailersInitial(value: any[]) {
    this._retailersInitial = value;

    if (!this.inStorageFilters) {
      this.saveFilters(true, "retailers");
    }
  }

  get periodInitial(): Period {
    return this._periodInitial;
  }

  set periodInitial(value: Period) {
    this._periodInitial = value;

    if (!this.inStorageFilters) {
      this.saveFilters(true, "period");
    }
  }

  get sectorsInitial(): any[] {
    return this._sectorsInitial;
  }

  set sectorsInitial(value: any[]) {
    this._sectorsInitial = value;

    if (!this.inStorageFilters) {
      this.saveFilters(true, "sectors");
    }
  }

  get categoriesInitial(): any[] {
    return this._categoriesInitial;
  }

  set categoriesInitial(value: any[]) {
    this._categoriesInitial = value;

    if (!this.inStorageFilters) {
      this.saveFilters(true, "categories");
    }
  }

  get sourcesInitial(): any[] {
    return this._sourcesInitial;
  }

  set sourcesInitial(value: any[]) {
    this._sourcesInitial = value;

    if (!this.inStorageFilters) {
      this.saveFilters(true, "sources");
    }
  }

  get sentimentsInitial(): any[] {
    return this._sentimentsInitial;
  }

  set sentimentsInitial(value: any[]) {
    this._sentimentsInitial = value;

    if (!this.inStorageFilters) {
      this.saveFilters(true, "sentiments");
    }
  }

  constructor() {
    this.getEnableFilters();
  }

  getEnableFilters() {
    const savedFilters: SavedFilters =
      !!window.localStorage.getItem("saved_filters") &&
      JSON.parse(window.localStorage.getItem("saved_filters"));
    if (savedFilters) {
      this.selectedFilters = savedFilters;
      this.inStorageFilters = true;
    } else {
      this.selectedFilters = SELECTED_FILTERS;
      this.inStorageFilters = false;
    }

    const savedFiltersRefInit = {
      retailerRole: ["period", "sectors", "categories"],
      otherRole: [
        "period",
        "sectors",
        "categories",
        "countries",
        "retailers",
        "sources",
        "sentiments",
      ],
    };

    this.savedFiltersRef =
      (!!window.localStorage.getItem("role_name") &&
        JSON.parse(window.localStorage.getItem("role_name"))) === "retailer"
        ? savedFiltersRefInit.retailerRole
        : savedFiltersRefInit.otherRole;
  }

  currentPageChange(currentPage: CurrentPage) {
    this.currentPage = currentPage;
    this.currentPageSource.next(currentPage);
  }

  selectCountries(countries: any[]) {
    this.countriesSource.next(countries);
    this.countries = countries;
  }

  selectRetailers(retailers: any[]) {
    this.retailersSource.next(retailers);
    this.retailers = retailers;
  }

  selectPeriod(period: Period) {
    this.periodSource.next(period);
    this.period = period;
  }

  selectSectors(sectors: any[]) {
    this.sectorsSource.next(sectors);
    this.sectors = sectors;
  }

  selectCategories(categories: any[]) {
    this.categoriesSource.next(categories);
    this.categories = categories;
  }

  selectCampaigns(campaigns: any[]) {
    this.cammpaignsSource.next(campaigns);
    this.campaigns = campaigns;
  }

  selectSources(sources: any[]) {
    this.sourcesSource.next(sources);
    this.sources = sources;
  }

  selectSentiments(sentiments: any[]) {
    this.sentimentSource.next(sentiments);
    this.sentiments = sentiments;
  }

  selectRetailSources(sources: any[]) {
    this.retailSourcesSource.next(sources);
    this.retailSources = sources;
  }

  selectRetailMediums(mediums: any[]) {
    this.retailMediumsSource.next(mediums);
    this.retailMediums = mediums;
  }

  selectRetailAudiences(audiences: any[]) {
    this.retailAudiencesSource.next(audiences);
    this.retailAudiences = audiences;
  }

  hideCategories(value) {
    this.hideCategoriesSource.next(value);
  }

  convertFiltersToQueryParams() {
    this.periodQParams = {
      startDate: moment(this.period.startDate).format("YYYY-MM-DD"),
      endDate: moment(this.period.endDate).format("YYYY-MM-DD"),
    };
    this.sectorsQParams =
      this.sectors && this.convertArrayToQueryParams(this.sectors, "id");
    this.categoriesQParams =
      this.categories && this.convertArrayToQueryParams(this.categories, "id");
    this.countriesQParams =
      this.countries && this.convertArrayToQueryParams(this.countries, "id");
    this.retailersQParams =
      this.retailers && this.convertArrayToQueryParams(this.retailers, "id");
    this.sourcesQParams =
      this.sources && this.convertArrayToQueryParams(this.sources, "id");
    this.campaignsQParams =
      this.campaigns && this.convertArrayToQueryParams(this.campaigns, "id");
    this.sentimentsQParams =
      this.sentiments && this.convertArrayToQueryParams(this.sentiments, "id");
  }

  convertRetailFiltersToQueryParams() {
    this.retailSourcesQParams =
      this.retailSources &&
      this.convertArrayToQueryParams(this.retailSources, "id");
    this.retailMediumsQParams =
      this.retailMediums &&
      this.convertArrayToQueryParams(this.retailMediums, "id");
    this.retailAudiencesQParams =
      this.retailAudiences &&
      this.convertArrayToQueryParams(this.retailAudiences, "id");
  }

  convertSentimentFiltersToQueryParams() {
    this.periodQParams = {
      startDate: moment(this.period.startDate).format("YYYY-MM-DD"),
      endDate: moment(this.period.endDate).format("YYYY-MM-DD"),
    };
    this.categoriesQParams =
      this.categories && this.convertArrayToQueryParams(this.categories, "id");
    this.countriesQParams =
      this.countries && this.convertArrayToQueryParams(this.countries, "id");
    this.retailersQParams =
      this.retailers && this.convertArrayToQueryParams(this.retailers, "id");
    this.sentimentsQParams =
      this.sentiments && this.convertArrayToQueryParams(this.sentiments, "id");
  }

  convertArrayToQueryParams(array, param?: string): string {
    let stringArray = "";
    for (let i = 0; i < array.length; i++) {
      if (param) {
        stringArray = array[i][param]
          ? stringArray.concat(",", array[i][param])
          : stringArray;
      } else {
        stringArray = array[i]
          ? stringArray.concat(",", array[i])
          : stringArray;
      }
    }

    return stringArray.substring(1);
  }

  restoreFilters() {
    this.countries = this.countriesInitial;
    this.retailers = this.retailersInitial;
    this.period = this.periodInitial;
    this.sectors = this.sectorsInitial;
    this.sources = this.sourcesInitial;
    this.categories = this.categoriesInitial;
    this.sentiments = this.sentimentsInitial;
    this.selectCampaigns([]);
    this.convertFiltersToQueryParams();
  }

  deleteFilters() {
    if (this.countriesInitial) {
      delete this._countriesInitial;
      delete this.countries;
    }

    if (this.retailersInitial) {
      delete this._retailersInitial;
      delete this.retailers;
    }

    if (this.periodInitial) {
      delete this._periodInitial;
      delete this.period;
    }

    if (this.sectorsInitial) {
      delete this._sectorsInitial;
      delete this.sectors;
    }

    if (this.sourcesInitial) {
      delete this._sourcesInitial;
      delete this.sources;
    }

    if (this.categoriesInitial) {
      delete this._categoriesInitial;
      delete this.categories;
    }

    if (this.sentimentsInitial) {
      delete this._sentimentsInitial;
      delete this.sentiments;
    }

    this.selectCampaigns([]);

    this.inStorageFilters = false;
    this.selectedFilters = SELECTED_FILTERS;
  }

  /**
   * Filters change
   * @param manualChange change made by "filter" button click triggered manually by the user
   */
  filtersChange(manualChange: boolean) {
    this.convertFiltersToQueryParams();
    this.filtersSource.next(manualChange);

    if (manualChange) {
      this.saveFilters();
    }
  }

  sentimentFiltersChange() {
    this.convertSentimentFiltersToQueryParams();
    this.filtersSource.next();
    this.saveFilters();
  }

  retailFiltersChange() {
    this.convertRetailFiltersToQueryParams();
    this.retailFiltersSource.next();
  }

  saveFilters(initialSaving?: boolean, filter?: string) {
    if (initialSaving) {
      const viewLevels = Object.getOwnPropertyNames(
        this.selectedFilters
      ) as ViewLevel[];

      for (const i of viewLevels) {
        const sections = Object.getOwnPropertyNames(
          this.selectedFilters[i]
        ) as Section[];

        for (const j of sections) {
          if (j === "sentiment_analysis") {
            continue;
          }
          const ref = this.selectedFilters[i][j];
          if (ref.hasOwnProperty(filter)) {
            ref[filter] = this[`${filter}Initial`];
          }
        }
      }

      for (const ref of this.savedFiltersRef) {
        if (!this[`${ref}Initial`]) {
          return;
        }
      }
    } else {
      this.saveFiltersByPage(this.currentPage);
    }

    window.localStorage.setItem(
      "saved_filters",
      JSON.stringify(this.selectedFilters)
    );
  }

  saveFiltersByPage(page: CurrentPage) {
    const ref = this.selectedFilters[page.viewLevel][page.section];

    for (const filterRef of this.savedFiltersRef) {
      if (ref.hasOwnProperty(filterRef)) {
        ref[filterRef] = this[filterRef];
      }
    }
  }

  saveUniqueFilter(page: CurrentPage, filterKey: string, value: any[]) {
    const ref = this.selectedFilters[page.viewLevel][page.section];
    ref[filterKey] = value;
  }

  getFiltersBySection() {
    return this.selectedFilters[this.currentPage.viewLevel][
      this.currentPage.section
    ];
  }

  getUniqueFilter(filterKey: string) {
    return this.selectedFilters[this.currentPage.viewLevel][
      this.currentPage.section
    ][filterKey];
  }
}
