export class User {
    id: number;
    email: string;
    first_name?: string;
    last_name?: string;
    avatar_url: string;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
    role_name?: string;
}
